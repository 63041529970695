.App-content {
  padding: 0em 0.5em 0em 0.5em;
}

.page-header {
  margin: 0em 0em 0.5em 0em;
  padding: 0em 0em 0em 0em;
  color: #fff;
}

.ant-page-header .ant-page-header-heading-extra {
  display: flex;
  justify-content: center;
}

.fw-bold {
  font-weight: 700;
}

.fw-light {
  font-weight: 300;
}

.fw-normal {
  font-weight: 400;
}

.fs-italics {
  font-style: italic;
}

.font-normal {
  font-family: var(--font-family);
  color: var(--text-color);
}

.font-size-small {
  font-size: 0.8rem;
}

.font-size-normal {
  font-size: 1rem;
}

.font-size-large {
  font-size: 1.5rem;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-align-center {
  align-items: center;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-justify-between {
  justify-content: space-between;
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.p-chips-token {
  margin: 0.2em 0em 0.2em 0em;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.highlight:hover {
  background-color: #abbcd4;
}
