.page-header .p-menubar {
  justify-content: space-between;
  background: none;
  border: none;
  padding: 0.5em 0em 0.5em 0em;
}

.page-header .p-menubar .p-menubar-button {
  display: none;
}

.page-header .page-header-title {
  font-size: 16px;
  font-weight: bold;
}

.page-header .page-header-subtitle {
  font-size: 16px;
  font-weight: normal;
  margin-left: 0.5em;
}
